@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:ital,wght@0,100..800;1,100..800&display=swap');

body {
  margin: 0;
  font-family: "JetBrains Mono", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none; 
  color: inherit; 
}

html {
  scroll-behavior: smooth;
  /* background: linear-gradient(
  to bottom right, 
  #F4E5FF 1%,  
  #8E38FE 50%,   
  #D6CEFF 100%   
); */
background-color: #fbfbfb;
}

