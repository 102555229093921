/* Footer.module.scss */
.footer {
    margin-top: 90px;
    padding: 0 20px;
  
    h1 {
      font-size: 36px;
      color: #282828;
    }
  
    p {
      font-size: 19px;
      margin-bottom: 10px;
      margin-top: 10px;
      color: #282828;
    }
  
    @media (max-width: 1300px) {
      margin-top: 60px;
      text-align: center;
  
      h1 {
        font-size: 28px;
      }
  
      p {
        font-size: 17px;
      }
    }
  
    @media (max-width: 480px) {
      margin-top: 40px;
  
      h1 {
        font-size: 24px;
      }
  
      p {
        font-size: 15px;
      }
    }
  }
  
  .logoSection {
    display: flex;
    align-items: center;
    gap: 10px;
  
    @media (max-width: 1300px) {
      justify-content: center;
    }
  }
  
  .name {
    font-weight: bold;
  }
  
  .footerBottom {
    border-top: 1px solid #d8d8d8;
    margin-top: 40px;
    padding-top: 20px;
    font-size: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  
    span {
      color: #282828;
    }
  
    @media (max-width: 1300px) {
      flex-direction: column;
      gap: 20px;
      font-size: 16px;
      text-align: center;
    }
  
    @media (max-width: 480px) {
      font-size: 14px;
    }
  }
  
  .span {
    font-size: 25px;
  }
  
  .icon {
    display: flex;
    align-items: center;
    gap: 10px;
  
    img {
      width: 30px;
      height: auto;
    }
  
    @media (max-width: 480px) {
      justify-content: center;
    }
  }