/* Content.module.scss */


.main {
  margin-top: 230px;
  

  @media (max-width: 768px) {
    margin-top: 100px;
    padding: 0 20px;
  }

  @media (max-width: 480px) {
    margin-top: 70px;
    padding: 0 15px;
  }
}


.h1 {
  max-width: 1100px;
  margin: 0 auto;
  font-weight: 500;
  font-size: 60px;
  text-align: center;
  color: #282828;
  
  // background: linear-gradient(90deg, #282828 20%, #b8b8b8 40%, #282828 80%);
  // -webkit-background-clip: text;
  // background-clip: text;
  // -webkit-text-fill-color: transparent;
  // background-size: 150% 200%;
  // animation: text 6s ease infinite;

  @media (max-width: 768px) {
    font-size: 32px;
    line-height: 1.2;
  }

  @media (max-width: 480px) {
    font-size: 25px;
    line-height: 1.3;
  }
}

// @keyframes text {
//   0% {
//     background-position: 200% 0;
//   }
//   100% {
//     background-position: -100% 0;
//   }
// }

.h2 {
  text-align: center;
  font-weight: 600;
  font-size: 41px;
  line-height: 1.05;
  color: #282828;

  @media (max-width: 768px) {
    font-size: 24px;
    line-height: 1.2;
  }

  @media (max-width: 480px) {
    font-size: 20px;
    line-height: 1.2;
  }
}

.p {
  margin-top: 40px;
  margin-bottom: 80px;
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  line-height: 1.5;
  color: #282828;

  @media (max-width: 768px) {
    font-size: 14px;
    margin-bottom: 40px;
    padding: 0 10px;
  }

  @media (max-width: 480px) {
    font-size: 13px;
    padding: 0 5px;
  }
}

.pInfo {
  text-align: center;
  font-weight: 500;
  font-size: 24px;
  line-height: 1.5;
  margin-top: 65px;
  color: #282828;

  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 1.4;
    margin-top: 40px;
    padding: 0 10px;
  }

  @media (max-width: 480px) {
    font-size: 14px;
    line-height: 1.4;
    padding: 0 5px;
  }
}

.MlButton {
  display: block;
  margin: 0 auto;
  margin-bottom: 120px;
  font-weight: 700;
  font-size: 18px;
  line-height: 19px;
  border-radius: 15px;
  padding: 22px 30px;
  outline: none;
  border: none;
  color: #fff;
  background: linear-gradient(90deg, #2409e0 0%, #cb15d9 100%);
  transition: transform 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    transform: scale(1.03);
  }

  @media (max-width: 768px) {
    font-size: 16px;
    padding: 15px 20px;
    margin-bottom: 80px;
  }

  @media (max-width: 480px) {
    font-size: 14px;
    padding: 12px 16px;
    margin-bottom: 60px;
    transform: scale(0.9);
  }
}

.imgContainer {
  position: relative;
  display: block;
  text-align: center;
  margin-bottom: 400px;

  @media (max-width: 768px) {
    margin-bottom: 200px;
  }
  @media (max-width: 480px) {
    margin-bottom: 100px;
  }

  .imgTop {
    width: 100%;
    @media (max-width: 768px) {
      width: 90%;
    }

    @media (max-width: 480px) {
      width: 100%;
    }
  }

  .imgBottom {
    position: absolute;
    left: 50%;
    bottom: -30%;
    transform: translateX(-50%);
    animation: float 2.5s ease-in-out infinite;
    width: 60%;
    max-width: 600px;

    
    @media (max-width: 768px) {
      width: 55%;
      bottom: -35%;
    }

    @media (max-width: 480px) {
      width: 50%;
      bottom: -40%;
    }
  }
}

@keyframes float {
  0% {
    transform: translateX(-50%) translateY(0);
  }
  50% {
    transform: translateX(-50%) translateY(-20px);
  }
  100% {
    transform: translateX(-50%) translateY(0);
  }
}

.quote {
  max-width: 1300px;
  margin: 0 auto;
  margin-bottom: 140px;
  padding: 20px 10px;
  border: 1px solid #ECECEC;
  border-radius: 15px;
  color: #282828;
  position: relative;

  &::before {
    content: '“';
    position: absolute;
    top: 5px;
    left: 10px;
    font-size: 35px;
    line-height: 1;
    color: #000000;
  }

  &::after {
    content: '”';
    position: absolute;
    bottom: 0;
    right: 10px;
    font-size: 35px;
    line-height: 1;
    color: #000000;
  }

  p {
    text-align: center;
    font-weight: 600;
    font-size: 24px;
    line-height: 1.5;

    @media (max-width: 768px) {
      font-size: 18px;
      line-height: 1.4;
      padding: 0 10px;
    }

    @media (max-width: 480px) {
      font-size: 16px;
      line-height: 1.4;
      padding: 0 5px;
    }
  }

  @media (max-width: 1300px) {
    margin-bottom: 100px;
    padding: 15px 5px;
  }
  @media (max-width: 768px) {
    margin-bottom: 80px;
    padding: 15px 5px;
  }

  @media (max-width: 480px) {
    margin-bottom: 60px;
    padding: 10px 5px;
  }
}

@keyframes shimmer {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(200%);
  }
}



.promoMessage {
  background: linear-gradient(90deg, #00ff13 0%, #ff00f7 100%);
  border: 3px ridge #6d00ff;
  color: #ffffff;
  padding: 15px;
  margin-bottom: 20px;
  font-size: 1.125rem; /* Equivalent to 18px */
  font-weight: bold;
  text-align: center;
  border-radius: 10px;
}

/* Media query for tablets and larger screens */
@media (min-width: 768px) {
  .promoMessage {
    font-size: 1.25rem; /* 20px */
    padding: 20px;
  }
  .promoMessage a {
    color: #0028ff;
    font-weight: 900;
    font-size: 20px;
    text-decoration: underline;
  }
}

/* Media query for mobile devices */
@media (max-width: 767px) {
  .promoMessage {
    font-size: 1rem; /* 16px */
    padding: 12px;
  }
  .promoMessage a {
    color: #0028ff;
    font-weight: 900;
    font-size: 20px;
    text-decoration: underline;
  }
}

/* Media query for very small screens */
@media (max-width: 480px) {
  .promoMessage {
    font-size: 0.9rem; /* 14px */
    padding: 10px;
  }
  .promoMessage a {
    color: #0028ff;
    font-weight: 900;
    font-size: 18px;
    text-decoration: underline;
  }
}

.promoMessage a:hover {
  color: #00fff5;
}

/* Ensure long words or links break properly on small screens */
.promoMessage a {
  word-break: break-word;
}
