/* Workflow.module.scss */

.main {
  margin-top: 85px;
  margin-bottom: 65px;
  padding: 80px 190px;
  text-align: center;
  border-radius: 20px;
  border: 1px solid #D8D8D8;
  overflow: hidden;
  background: #060606;

  @media (max-width: 768px) {
    margin-top: 60px;
    margin-bottom: 50px;
    padding: 60px 40px;
  }

  @media (max-width: 480px) {
    margin-top: 40px;
    margin-bottom: 30px;
    padding: 40px 20px;
  }

  code {
    @media (max-width: 768px) {
      font-size: 12px;
      padding: 20px;
    }
  
    @media (max-width: 480px) {
      font-size: 11px;
      padding: 15px;
    }
  }
}

.h1 {
  font-weight: 700;
  font-size: 41px;
  color: #FFFFFF;

  @media (max-width: 768px) {
    font-size: 32px;
  }

  @media (max-width: 480px) {
    font-size: 24px;
  }
}

.p {
  font-weight: 600;
  font-size: 24px;
  color: #FFFFFF;

  @media (max-width: 768px) {
    font-size: 20px;
  }

  @media (max-width: 480px) {
    font-size: 16px;
  }
}

.btn {
  color: #1e1e1e;
  background: #ededed;
  padding: 24px 85px;
  border-radius: 15px;
  border: none;
  font-size: 16px;
  font-weight: bold;
  font-family: "Inconsolata", serif;
  cursor: pointer;
  position: relative;

  @media (max-width: 768px) {
    padding: 20px 60px;
    font-size: 14px;
  }

  @media (max-width: 480px) {
    padding: 16px 30px;
    font-size: 12px;
  }
}

.buttonContainer {
  display: inline-block;
  margin-bottom: 16px;
}

.circles {
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
  gap: 6px;
  z-index: 1;

  @media (max-width: 480px) {
    top: 6px;
    left: 6px;
    gap: 4px;
  }
}

.circle {
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background-color: grey;

  @media (max-width: 480px) {
    width: 9px;
    height: 9px;
  }
}

.red {
  background-color: #ff5f56;
}

.yellow {
  background-color: #ffbd2e;
}

.green {
  background-color: #27c93f;
}

.buttonWrapper {
  position: relative;
  display: inline-block;
  margin-top: 5px;
}

.tooltip {
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  color: #333;
  padding: 6px 10px;
  border-radius: 4px;
  font-size: 14px;
  white-space: nowrap;
  z-index: 100;
  opacity: 0;
  animation: fadeIn 0.3s forwards;

  @media (max-width: 480px) {
    font-size: 12px;
    top: -25px;
  }
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}



