.faq-container {
  font-family: Arial, sans-serif;
  max-width: 1000px;
  margin: 0 auto;
  margin-top: 80px;
  padding: 20px;

  @media (max-width: 768px) {
    padding: 15px;
  }

  @media (max-width: 480px) {
    margin-top: 40px;
    padding: 10px;
  }
}

h2 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 28px;
  color: #282828;

  @media (max-width: 768px) {
    font-size: 24px;
  }

  @media (max-width: 480px) {
    font-size: 20px;
  }
}

.faq-list {
  list-style: none;
  padding: 0;
}

.faq-item {
  margin-bottom: 20px;
  color: #282828;
  border: 1px solid #ddd;
  border-radius: 10px;
}

.faq-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 24px;
  padding: 25px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  color: #282828;

  @media (max-width: 768px) {
    font-size: 20px;
    padding: 12px;
  }

  @media (max-width: 480px) {
    font-size: 18px;
    padding: 10px;
  }
}

.faq-question:not(.active):hover {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 5px;
}

.faq-answer {
  height: 0;
  overflow: hidden;
  opacity: 0;
  padding: 0 25px;
  font-size: 18px;
  transition: height 0.3s ease, opacity 0.3s ease, padding 0.3s ease;

  @media (max-width: 768px) {
    font-size: 16px;
    padding-left: 8px;
  }

  @media (max-width: 480px) {
    font-size: 14px;
    padding-left: 5px;
  }
}

.faq-question span:last-child {
  font-size: 24px;

  @media (max-width: 768px) {
    font-size: 20px;
  }

  @media (max-width: 480px) {
    font-size: 18px;
  }
}