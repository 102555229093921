.main {
    
    h1 {
        font-size: 45px;
        padding-bottom: 30px;
        border-bottom: 1px solid #d8d8d8;
    }
}

.mainText {
    p { 
        font-size: 20px;
        font-weight: 600;
        max-width: 1000px;
    }
    h2 {
        text-align: left;
        padding: 30px 0;
    }
}

.pContetn {
    padding: 20px;
}