/* OpenModels.module.scss */
.container {
  display: flex;
  flex-direction: column;
  padding: 20px 10px;
  max-width: 1300px;
  margin: 0 auto;
}

.topSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  @media (max-width: 1300px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.text {
  max-width: 60%;
  @media (max-width: 1300px) {
    margin: 0 auto;
    text-align: center;
    max-width: 100%;
    margin-bottom: 20px;
  }

  @media (max-width: 768px) {
    max-width: 100%;
    margin-bottom: 20px;
  }
}

.h1 {
  font-weight: 800;
  font-size: 32px;
  background: linear-gradient(to right, #06E528 0%, #6606F5 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  margin-bottom: 16px;

  @media (max-width: 768px) {
    font-size: 28px;
  }

  @media (max-width: 480px) {
    font-size: 24px;
  }
}

.paragraph {
  font-size: 18px;
  font-weight: 500;
  color: #282828;

  @media (max-width: 768px) {
    font-size: 16px;
  }

  @media (max-width: 480px) {
    font-size: 14px;
  }
}

.cardsRight {
  display: flex;
  flex-direction: row;
  text-align: left;
  gap: 20px;
  p {
    width: 200px;
  }

  @media (max-width: 1300px) {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  

  @media (max-width: 550px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    p {
      width: 100%;
    }
  }
}

.bottomSection {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;

  @media (max-width: 1024px) {
    grid-template-columns: repeat(5, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 480px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.card {
  padding: 20px;
  text-align: left;
  border-radius: 8px;
  border: 1px solid #d8d8d8;
  background-color: #fff;
  max-width: 200px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;

  &:hover {
    transform: translateY(-8px);
    box-shadow: 0 15px 25px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 768px) {
    max-width: 100%;
    padding: 16px;
  }

  @media (max-width: 480px) {
    padding: 12px;
  }
}

.card img {
  border: 1px solid #d8d8d8;
  border-radius: 10px;
  padding: 5px;
  width: 25px;
  height: auto;
  margin-bottom: 8px;

  @media (max-width: 480px) {
    width: 20px;
    padding: 4px;
  }
}

.card p {
  font-weight: 800;
  margin-bottom: 4px;
  font-size: 18px;

  @media (max-width: 768px) {
    font-size: 16px;
  }

  @media (max-width: 480px) {
    font-size: 14px;
  }
}

.card span {
  color: #555;

  @media (max-width: 480px) {
    font-size: 12px;
  }
}

.hideBetween770and1300 {
  @media (max-width: 1300px) and (min-width: 770px) {
    display: none;
  }
}