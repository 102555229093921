

.floating-shapes {
    position: absolute;
    top: 150px;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none; /* Prevent shapes from blocking interactions */
    z-index: 0; /* Ensure they are behind other content */
  }
  
  .floating-shape {
    position: absolute;
    animation: moveUp 1.5s ease-out forwards, float 5s infinite ease-in-out 1.5s;
    opacity: 0.8;
    
  }
  
  /* Individual shape positions and delays */
  .shape-1 {
    top: 35%;
    left: 75%;
    width: 500px;
    height: auto;
    /* animation-delay: 0s; */
  }
  
  .shape-2 {
    top: -10%;
    left: 70%;
    width: 380px;
    height: auto;
    /* animation-delay: 1s; */
  }
  
  .shape-3 {
    top: 50%;
    left: 5%;
    width: 380px;
    height: auto;
    /* animation-delay: 2s; */
  }
  
  .shape-4 {
    top: 10%;
    left: 75%;
    width: 380px;
    height: auto;
    /* animation-delay: 3s; */
  }
  
  
  .shape-5 {
    top: 10%;
    left: 0%;
    width: 450px;
    height: auto;
    /* animation-delay: 4s; */
  }

  @media (max-width: 1468px) {
    .shape-1, .shape-2, .shape-3, .shape-4 {
      width: 350px;
    }
    .shape-5 {
      width: 400px;
      top: 17%;
      left: -12%;
    }
  }
  
  
  /* Float animation */
  @keyframes float {
    0% {
      transform: translateY(0px);
    }
    50% {
      transform: translateY(-20px);
    }
    100% {
      transform: translateY(0px);
    }
  }
  
  
  /* Upward movement animation */
  @keyframes moveUp {
    0% {
      transform: translateY(200px); /* Start below */
      opacity: 0; /* Invisible */
    }
    100% {
      transform: translateY(0px); /* Final position */
      opacity: 0.8; /* Fully visible */
    }
  }