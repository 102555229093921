

.container {
  position: fixed;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  max-width: 1500px;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 25px;
  background: #060606;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(255, 255, 255, 0.3);

  h2 {
    color: #fff;
  }
}

.logoContainer {
  display: flex;
  align-items: center;
  gap: 12px;
}

.navLinks {
  display: flex;
  align-items: center;
  gap: 20px;

  p {

    &:hover {
      color: rgb(111, 110, 110)
    }
  }
  
}

.navLinks a {
  text-decoration: none;
  color: rgb(244, 244, 244);
  
}

.p {
  margin: 0;
  font-family: "Inconsolata", serif;
  font-weight: 600;
  font-size: 18px;
  color: #f4f4f4;
}

.menuIcon {
  display: none;
  flex-direction: column;
  cursor: pointer;
  margin-left: auto;
  height: 30px;

  .burger {
    width: 25px;
    height: 3px;
    background-color: #fff;
    margin: 3px 0;
  }
}


@media (max-width: 768px) {
  .container {
    position: static;
    margin: 0 auto;
    transform: none;
    width: 80%;
    padding: 5px 10px;
    justify-content: space-between;
    transform: scale(0.9);
    

    h2 {
      font-size: 20px;
    }
  }

  .logoContainer {
    flex: 1;
    img {
      width: 30px;
      height: 30px;
    }
  }

  .navLinks {
    display: none;
    position: absolute;
    top: 70px;
    max-width: 80%;
    border-radius: 15px;
    margin: 0 auto;
    left: 0;
    right: 0;
    background: #060606;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 20px 0;
   
  }

  .navLinks.active {
    display: flex;
  }

  .menuIcon {
    display: flex;
  }
}